<template>
  <div class="time-tooltip" :style="{ left: position + '%' }">
    {{ formattedTimestamp }}
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'TimeTooltip',
  props: {
    timestamp: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const parseTimestamp = (timestamp) => {
      // Remove the "/Z3Z" suffix if present
      const cleanTimestamp = timestamp.replace('/Z3Z', '');
      const matches = cleanTimestamp.match(/^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/);
      if (matches) {
        return new Date(Date.UTC(
          parseInt(matches[1]),
          parseInt(matches[2]) - 1,
          parseInt(matches[3]),
          parseInt(matches[4]),
          parseInt(matches[5]),
          parseInt(matches[6])
        ));
      }
      return new Date(NaN);
    };

    const formattedTimestamp = computed(() => {
      const date = parseTimestamp(props.timestamp);
      if (isNaN(date.getTime())) return 'Invalid Date';
      
      const options = {
        weekday: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: props.timeZone
      };
      
      return new Intl.DateTimeFormat('en-US', options).format(date);
    });

    return {
      formattedTimestamp
    };
  }
};
</script>

<style scoped>
.time-tooltip {
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  background-color: #ffa500;
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.time-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffa500 transparent transparent transparent;
}
</style>
