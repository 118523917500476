<template>
  <div class="day-header">
    <div 
      v-for="(day, index) in days" 
      :key="index" 
      class="day-label"
      :style="{ left: day.position + 'px' }"
    >
      {{ day.label }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'DayHeader',
  props: {
    issueTime: {
      type: String,
      required: true
    },
    hoursFromIssueTime: {
      type: Array,
      required: true
    },
    hourWidth: {
      type: Number,
      default: 100
    }
  },
  setup(props) {
    const parseDate = (issueTime) => {
      // Format expected: YYYYMMDD_HH
      const year = parseInt(issueTime.substring(0, 4));
      const month = parseInt(issueTime.substring(4, 6)) - 1;
      const day = parseInt(issueTime.substring(6, 8));
      const hour = parseInt(issueTime.substring(9, 11));
      
      // Create date in UTC
      return new Date(Date.UTC(year, month, day, hour));
    };

    const days = computed(() => {
      if (!props.issueTime || !props.hoursFromIssueTime.length) {
        return [];
      }

      const result = [];
      let currentDate = null;

      try {
        const baseDate = parseDate(props.issueTime);
        if (isNaN(baseDate.getTime())) {
          console.error('Invalid issue time:', props.issueTime);
          return [];
        }

        props.hoursFromIssueTime.forEach((hours, index) => {
          const date = new Date(baseDate);
          date.setUTCHours(date.getUTCHours() + hours);
          
          // Get day of month as a number
          const day = date.getDate();
          
          // Get weekday name
          const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
          
          // Combine them: "Monday 17"
          const dayStr = `${weekday} ${day}`;
          
          if (dayStr !== currentDate) {
            currentDate = dayStr;
            // Add 10px offset to align with centered text in cells
            const position = index * (props.hourWidth / 2.5) + 10;
            result.push({
              label: dayStr,
              position: position
            });
          }
        });
      } catch (error) {
        console.error('Error processing dates:', error);
        return [];
      }

      return result;
    });

    return {
      days
    };
  }
};
</script>

<style scoped>
.day-header {
  position: relative;
  height: 24px;
  margin-bottom: 4px;
  margin-top: -20px;
  width: 100%;
}

.day-label {
  position: absolute;
  font-weight: 500;
  color: #666;
  white-space: nowrap;
  z-index: 1;
}
</style>
