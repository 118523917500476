<template>
  <div class="place-search-container">
    <div class="search-box">
      <input 
        type="text" 
        v-model="searchQuery" 
        @input="handleInput"
        @keydown.enter.prevent="handleEnterKey"
        @keydown.down.prevent="navigateResults(1)"
        @keydown.up.prevent="navigateResults(-1)"
        placeholder="Search for a place..." 
        class="search-input"
        ref="searchInput"
      />
      <button v-if="searchQuery" @click="clearSearch" class="clear-button">×</button>
      <button v-if="isLoading" class="loading-indicator">
        <span class="spinner"></span>
      </button>
      <button @click="getUserLocation" class="location-button" title="Use your current location">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#4B77D1">
          <path d="M440-40v-80q-125-14-214.5-103.5T122-438H42v-80h80q14-125 103.5-214.5T440-836v-80h80v80q125 14 214.5 103.5T838-518h80v80h-80q-14 125-103.5 214.5T520-120v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Z"/>
          <circle cx="480" cy="-480" r="150"/>
      </svg>
      </button>
    </div>
    <div v-if="searchResults.length > 0" class="search-results">
      <div 
        v-for="(result, index) in searchResults" 
        :key="index" 
        @click="selectPlace(result)" 
        class="search-result-item"
        :class="{ 'search-result-active': index === selectedResultIndex }"
      >
        <div class="result-name">{{ result.name }}</div>
        <div class="result-address">{{ result.address }}</div>
      </div>
    </div>
    <div v-if="error" class="search-error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import debounce from 'lodash.debounce';

export default {
  name: 'PlaceSearch',
  emits: ['place-selected'],
  setup(props, { emit }) {
    const searchQuery = ref('');
    const searchResults = ref([]);
    const isLoading = ref(false);
    const error = ref('');
    const searchInput = ref(null);
    const selectedResultIndex = ref(-1);
    
    // Use OpenStreetMap Nominatim API for geocoding
    const searchPlaces = async (query) => {
      if (!query || query.length < 3) {
        searchResults.value = [];
        error.value = '';
        return;
      }
      
      isLoading.value = true;
      error.value = '';
      
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&limit=5`,
          {
            headers: {
              'Accept-Language': 'en-US,en',
              'User-Agent': 'ExpectedWeather/1.0'
            }
          }
        );
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        
        if (data.length === 0) {
          error.value = 'No places found. Try a different search term.';
        }

        searchResults.value = data.map(item => ({
          id: item.place_id,
          name: item.display_name.split(',')[0],
          address: item.display_name,
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lon)
        }));

        // Reset selected index when new results arrive
        selectedResultIndex.value = -1;
      } catch (error) {
        console.error('Error searching for places:', error);
        searchResults.value = [];
        error.value = 'Error searching for places. Please try again.';
      } finally {
        isLoading.value = false;
      }
    };
    
    // Debounce search to avoid too many API calls
    const debouncedSearch = debounce(searchPlaces, 300);
    
    const handleInput = () => {
      debouncedSearch(searchQuery.value);
    };
    
    const selectPlace = (place) => {
      if (place) {
        emit('place-selected', {
          name: place.name,
          address: place.address,
          coordinates: {
            lat: place.lat,
            lng: place.lng
          }
        });
        searchResults.value = [];
        // Don't clear the search query so users can see what they selected
      }
    };
    
    const clearSearch = () => {
      searchQuery.value = '';
      searchResults.value = [];
      error.value = '';
      selectedResultIndex.value = -1;
      // Focus the input after clearing
      if (searchInput.value) {
        searchInput.value.focus();
      }
    };
    
    // Handle keyboard navigation
    const navigateResults = (direction) => {
      if (searchResults.value.length === 0) return;
      
      // Calculate new index with wrapping
      if (direction > 0) {
        // Moving down
        selectedResultIndex.value = 
          selectedResultIndex.value === searchResults.value.length - 1 
            ? 0 
            : selectedResultIndex.value + 1;
      } else {
        // Moving up
        selectedResultIndex.value = 
          selectedResultIndex.value <= 0 
            ? searchResults.value.length - 1 
            : selectedResultIndex.value - 1;
      }
      
      // Ensure the selected item is visible in the scrollable container
      ensureSelectedIsVisible();
    };
    
    const ensureSelectedIsVisible = () => {
      // Wait for DOM update
      setTimeout(() => {
        const container = document.querySelector('.search-results');
        const selectedItem = document.querySelector('.search-result-active');
        
        if (container && selectedItem) {
          const containerRect = container.getBoundingClientRect();
          const selectedRect = selectedItem.getBoundingClientRect();
          
          // Check if the selected item is outside the visible area
          if (selectedRect.bottom > containerRect.bottom) {
            // Scroll down to make visible
            selectedItem.scrollIntoView(false);
          } else if (selectedRect.top < containerRect.top) {
            // Scroll up to make visible
            selectedItem.scrollIntoView(true);
          }
        }
      }, 10);
    };
    
    const handleEnterKey = () => {
      if (searchResults.value.length > 0) {
        // If an item is selected, use that one
        if (selectedResultIndex.value >= 0) {
          selectPlace(searchResults.value[selectedResultIndex.value]);
        } else {
          // Otherwise use the first result (default behavior)
          selectPlace(searchResults.value[0]);
        }
      }
    };
    
    // Get user's current location
    const getUserLocation = () => {
      if (navigator.geolocation) {
        isLoading.value = true;
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              // Reverse geocode the coordinates to get the place name
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}&zoom=18&addressdetails=1`,
                {
                  headers: {
                    'Accept-Language': 'en-US,en',
                    'User-Agent': 'ExpectedWeather/1.0'
                  }
                }
              );
              
              if (!response.ok) {
                throw new Error('Error getting location information');
              }
              
              const data = await response.json();
      
              // Emit the place-selected event with the user's location
              emit('place-selected', {
                name: data.address.city,
                address: data.address.city,
                coordinates: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                }
              });
              
              // Update the search input with the place name
              searchQuery.value = data.address.city;
            } catch (error) {
              console.error('Error reverse geocoding:', error);
              
              // Even if reverse geocoding fails, still use the coordinates
              emit('place-selected', {
                name: 'Your Location',
                address: `Lat: ${position.coords.latitude.toFixed(6)}, Lng: ${position.coords.longitude.toFixed(6)}`,
                coordinates: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                }
              });
              
              searchQuery.value = 'Your Location';
            } finally {
              isLoading.value = false;
            }
          },
          (error) => {
            console.error('Geolocation error:', error);
            isLoading.value = false;
            error.value = 'Could not get your location. Please check your browser permissions.';
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        );
      } else {
        error.value = 'Geolocation is not supported by your browser.';
      }
    };
    
    // Clear results when query is empty
    watch(searchQuery, (newValue) => {
      if (!newValue) {
        searchResults.value = [];
        error.value = '';
        selectedResultIndex.value = -1;
      }
    });
    
    onMounted(() => {
      // Add event listener to close search results when clicking outside
      document.addEventListener('click', (event) => {
        const container = document.querySelector('.place-search-container');
        if (container && !container.contains(event.target)) {
          searchResults.value = [];
          selectedResultIndex.value = -1;
        }
      });
    });
    
    return {
      searchQuery,
      searchResults,
      isLoading,
      error,
      searchInput,
      selectedResultIndex,
      handleInput,
      selectPlace,
      clearSearch,
      navigateResults,
      handleEnterKey,
      getUserLocation
    };
  }
};
</script>

<style scoped>
.place-search-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  width: 300px;
  max-width: calc(100% - 40px);
}

.search-box {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 10px 35px 10px 15px;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  outline: none;
  background-color: white;
}

.search-input:focus {
  border-color: orange;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-button:hover {
  color: #666;
}

.location-button {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-button:hover svg {
  fill: orange;
}

.location-button svg {
  transition: fill 0.2s ease;
}

.loading-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
}

.spinner {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: orange;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.search-results {
  margin-top: 5px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
}

.search-result-item {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover,
.search-result-active {
  background-color: #f9f9f9;
}

.search-result-active {
  border-left: 3px solid orange;
}

.result-name {
  font-weight: 500;
  margin-bottom: 3px;
}

.result-address {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-error {
  margin-top: 5px;
  padding: 10px;
  background-color: #ffebee;
  color: #c62828;
  border-radius: 12px;
  font-size: 12px;
  text-align: center;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .place-search-container {
    top: 10px;
    left: 10px;
    width: 60%;
    max-width: 230px;
  }
}
</style>
