<template>
  <div class="map-container">
    <div id="map" ref="mapContainer"></div>
    <LayerSelector @layer-selected="changeLayer" />
    <PlaceSearch @place-selected="handlePlaceSelection" />
    <MapControls @dimension-changed="changeDimension" @play-pause-changed="handlePlayPauseChange" />
    <div class="bottom-controls">
      <TimeSlider 
        :timestamps="timestamps" 
        @timestamp-selected="changeTimestamp" 
        @play-pause-changed="handlePlayPauseChange"
        v-if="timestamps.length"
        class="time-slider"
      />
      <Legend 
        :legendData="currentLegendData" 
        v-if="currentLegendData"
        class="legend"
      />
    </div>
    <ValueMarker
      v-if="markerData && markerData.position"
      :position="markerData.position"
      :value="markerData.value"
      :unit="currentLegendData?.unit || ''"
      :mapHeight="mapHeight"
      :coordinates="markerData.lngLat"
      :initialShowForecast="markerData.showForecast || false"
      @close-marker="closeMarker"
      @forecast-visibility-changed="updateForecastVisibility"
    />
  </div>
</template>

<script>
import { onMounted, ref, nextTick, onUnmounted } from 'vue';
// ---- Remove maplibre-gl imports ----
// import maplibregl from 'maplibre-gl';
// import 'maplibre-gl/dist/maplibre-gl.css';

// ---- Add mapbox-gl imports ----
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import LayerSelector from './LayerSelector.vue';
import Legend from './Legend.vue';
import TimeSlider from './TimeSlider.vue';
import ValueMarker from './ValueMarker.vue';
import MapControls from './MapControls.vue';
import PlaceSearch from './PlaceSearch.vue';

export default {
  components: {
    LayerSelector,
    Legend,
    TimeSlider,
    ValueMarker,
    MapControls,
    PlaceSearch
  },
  setup() {
    const mapContainer = ref(null);
    let map = null;
    const currentLegendData = ref(null);
    const timestamps = ref([]);
    const currentTimestamp = ref(null);
    const layerUrl = ref('');
    const mapHeight = ref(0);
    const markerData = ref(null);
    const forecastVisible = ref(false);
    
    // Get saved map style preference or use default
    const savedDimension = localStorage.getItem('mapDimension');
    const initialStyle = savedDimension === '3D' 
      ? 'mapbox://styles/mapbox/light-v11'
      : 'mapbox://styles/mapbox/light-v10';
    const mapStyle = ref(initialStyle);

    // Default cache values when not playing
    const defaultCacheForward = 0;
    const defaultCacheBackward = 0;
    
    // Enhanced cache values when playing
    const playingCacheForward = 2;
    const playingCacheBackward = 0;
    
    // Reactive cache values that will change based on play/pause state
    const cacheForward = ref(defaultCacheForward);
    const cacheBackward = ref(defaultCacheBackward);
    
    let layers = {}; // stores added layers keyed by timestamp

    const currentLayerDescription = ref(null);
    const currentTimestampIndex = ref(-1);

    // Opacity values
    const opacityValue = 0.5;
    const getOpacityPaintProperty = (type) => {
      if (type === 'symbol') {
        return 'text-opacity';
      } else {
        return type + '-opacity';
      }
    };
    const getOpacityValue = (type) => {
      if (type === 'symbol') {
        return 1;
      } else {
        return opacityValue;
      }
    };

    const isLayerVisible = (layerId) => {
      if (!layers[layerId]) return false;
      const prop = getOpacityPaintProperty(currentLayerDescription.value.type);
      const opacity = map.getPaintProperty(layerId, prop);
      return opacity > 0;
    };

    // ---- Add your Mapbox token here ----
    mapboxgl.accessToken = 'pk.eyJ1IjoiamF3b3Jza2l3b2oiLCJhIjoiY2pldG1zYjBwMDI2YTJ4bnpnbjBrYTJ6bSJ9.DXTExrussJkU_JxtpLPSzg';

    // Fetch layer info from your API
    const fetchLayerInfo = async (layerId) => {
      const API_URL = process.env.VUE_APP_API_URL;
      const descriptionResponse = await fetch(`${API_URL}/description/${layerId}`);
      const description = await descriptionResponse.json();

      const timestampsResponse = await fetch(`${API_URL}/timestamps/${layerId}`);
      const timestampsData = await timestampsResponse.json();
      timestamps.value = timestampsData.timestamps;
      currentTimestamp.value = timestamps.value[0];
      layerUrl.value = timestampsData.url;

      currentLayerDescription.value = description;
      currentLegendData.value = description.legend;

      return { description, timestampsData };
    };

    // Instead of single addWeatherLayer, use one layer per timestamp
    const addLayer = (timestamp, description) => {
      if (layers[timestamp]) {
        return; // already added
      }
      const sourceUrl = layerUrl.value.replace('${timestamp}', timestamp);

      // Add a dedicated source for this timestamp
      map.addSource(timestamp, {
        type: description.sourceType || 'vector',
        tiles: [sourceUrl],
        minzoom: description.minZoom,
        maxzoom: description.maxZoom
      });

      let layerDef = {
        id: timestamp,
        type: description.type,
        source: timestamp,
        'source-layer': 'vector'
      };

      // Start with 0.0 opacity
      let paintStyle = {};
      if (description.type === 'fill') {
        paintStyle = {
          'fill-color': description.style.paint['fill-color'],
          'fill-outline-color': [
            'rgba',
            // Extract the RGB components from the fill-color
            ['to-number', ['at', 0, ['to-rgba', description.style.paint['fill-color']]]],
            ['to-number', ['at', 1, ['to-rgba', description.style.paint['fill-color']]]],
            ['to-number', ['at', 2, ['to-rgba', description.style.paint['fill-color']]]],
            opacityValue / 2  //I don't know why we need to divide it by 2 - it is more aligned with fill color
          ],
          'fill-opacity': 0.0,
          'fill-opacity-transition': { duration: 0, delay: 0 }
        };        
      } else if (description.type === 'raster') {
        paintStyle = {
          'raster-opacity': 0.0,
          'raster-opacity-transition': { duration: 0, delay: 0 }
        };
      } else if (description.type === 'line') {
        paintStyle = {
          'line-opacity': 0.0,
          'line-opacity-transition': { duration: 0, delay: 0 }
        };
      } else if (description.type === 'symbol') {
        paintStyle = {
          'text-opacity': 0.0,
          'text-opacity-transition': { duration: 0, delay: 0 },
          'icon-opacity': 0.0,
          'icon-opacity-transition': { duration: 0, delay: 0 }
        };
      }

      layerDef.paint = { ...paintStyle };

      map.addLayer(layerDef);
      layers[timestamp] = layerDef;
    };

    const removeLayer = (timestamp) => {
      if (!layers[timestamp]) return;
      if (map.getLayer(timestamp)) {
        map.removeLayer(timestamp);
      }
      if (map.getSource(timestamp)) {
        map.removeSource(timestamp);
      }
      delete layers[timestamp];
    };

    const calculateCurrentTimestampIndex = () => {
      if (currentTimestampIndex.value === -1) {
        const currentDate = new Date();
        const currentTime = currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000;

        let minIndex = 0;
        let minValue = Infinity;

        for (let i = 0; i < timestamps.value.length; i++) {
          const timestamp = timestamps.value[i];
          const year = parseInt(timestamp.substring(0, 4));
          const month = parseInt(timestamp.substring(4, 6)) - 1;
          const day = parseInt(timestamp.substring(6, 8));
          const hour = parseInt(timestamp.substring(9, 11));
          const minute = parseInt(timestamp.substring(11, 13));
          const second = parseInt(timestamp.substring(13, 15));
          const timestampDate = Date.UTC(year, month, day, hour, minute, second);
          const diff = Math.abs(currentTime - timestampDate);
          if (diff < minValue) {
            minValue = diff;
            minIndex = i;
          }
        }

        currentTimestampIndex.value = minIndex;
      }
    };

    const addLayers = () => {
      const backwardIndex = Math.max(currentTimestampIndex.value - cacheBackward.value, 0);
      const forwardIndex = Math.min(currentTimestampIndex.value + cacheForward.value, timestamps.value.length - 1);

      for (let i = 0; i < timestamps.value.length; i++) {
        const timestamp = timestamps.value[i];
        if (i >= backwardIndex && i <= forwardIndex) {
          addLayer(timestamp, currentLayerDescription.value);
          const prop = getOpacityPaintProperty(currentLayerDescription.value.type);
          const val = i === currentTimestampIndex.value ? getOpacityValue(currentLayerDescription.value.type) : 0.0;
          map.setPaintProperty(timestamp, prop, val);
        } else {
          removeLayer(timestamp);
        }
      }
    };

    const updateLayerTimestamps = () => {
      const backwardIndex = Math.max(currentTimestampIndex.value - cacheBackward.value, 0);
      const forwardIndex = Math.min(currentTimestampIndex.value + cacheForward.value, timestamps.value.length - 1);

      for (let i = 0; i < timestamps.value.length; i++) {
        const timestamp = timestamps.value[i];
        if (i >= backwardIndex && i <= forwardIndex) {
          addLayer(timestamp, currentLayerDescription.value);
        } else {
          removeLayer(timestamp);
        }

        if (layers[timestamp]) {
          const prop = getOpacityPaintProperty(currentLayerDescription.value.type);
          const val = (i === currentTimestampIndex.value) ? getOpacityValue(currentLayerDescription.value.type) : 0.0;
          map.setPaintProperty(timestamp, prop, val);
        }
      }
    };

    const selectLayer = () => {
      // Clear existing layers
      for (const t of Object.keys(layers)) {
        removeLayer(t);
      }
      calculateCurrentTimestampIndex();
      addLayers();
    };

    const selectTimestampIndex = (index) => {
      currentTimestampIndex.value = index;
      updateLayerTimestamps();
    };

    const changeLayer = async (layer) => {
      await fetchLayerInfo(layer.id);
      selectLayer();
      updateMarkerValue();
    };

    const changeTimestamp = (timestamp) => {
      const idx = timestamps.value.indexOf(timestamp);
      if (idx !== -1) {
        selectTimestampIndex(idx);
      }
      // Update marker after the map re-renders
      map.once('idle', () => {
        updateMarkerValue();
      });
    };
    
    // Handle play/pause state changes from TimeSlider
    const handlePlayPauseChange = (isPlaying) => {
      if (isPlaying) {
        // Increase cache when playing to preload more frames
        cacheForward.value = playingCacheForward;
        cacheBackward.value = playingCacheBackward;
      } else {
        // Reduce cache when paused to save resources
        cacheForward.value = defaultCacheForward;
        cacheBackward.value = defaultCacheBackward;
      }
      // Update layers with new cache settings
      updateLayerTimestamps();
    };

    const handlePlaceSelection = (place) => {
      // Fly to the selected place
      map.flyTo({
        center: [place.coordinates.lng, place.coordinates.lat],
        zoom: 5,
        essential: true
      });

      // Create a marker at the selected location
      markerData.value = {
        lngLat: {
          lng: place.coordinates.lng,
          lat: place.coordinates.lat
        },
        value: place.name,
        position: map.project([place.coordinates.lng, place.coordinates.lat]),
        showForecast: true // Set to true to automatically open the forecast
      };

      // Update marker value after the map finishes moving
      map.once('moveend', () => {
        updateMarkerValue();
      });
    };

    const handleMapClick = (e) => {
      const features = map.queryRenderedFeatures(e.point);
      const visibleFeatures = features.filter(f => isLayerVisible(f.layer.id));
      const feature = visibleFeatures[0];
      
      // Store the current showForecast value if it exists
      const showForecast = markerData.value?.showForecast || false;
      
      if (feature && feature.properties.value) {
        markerData.value = {
          lngLat: e.lngLat,
          value: feature.properties.value,
          position: { x: e.point.x, y: e.point.y },
          showForecast: showForecast // Preserve the showForecast state
        };
      } else {
        markerData.value = {
          lngLat: e.lngLat,
          value: '',
          position: { x: e.point.x, y: e.point.y },
          showForecast: showForecast // Preserve the showForecast state
        };
      }
    };

    const updateMarkerScreenPosition = () => {
      if (!markerData.value || !markerData.value.lngLat) return;
      const point = map.project(markerData.value.lngLat);
      markerData.value = {
        ...markerData.value,
        position: { x: point.x, y: point.y }
      };
    };

    const updateMarkerValue = async () => {
      if (markerData.value && markerData.value.lngLat) {
        const point = map.project(markerData.value.lngLat);
        const features = map.queryRenderedFeatures(point);
        const visibleFeatures = features.filter(f => isLayerVisible(f.layer.id));
        const feature = visibleFeatures[0];
        if (feature && feature.properties.value) {
          markerData.value = {
            ...markerData.value,
            value: feature.properties.value
          };
        } else {
          markerData.value = {
            ...markerData.value,
            value: ''
          };
        }
      }
    };

    const closeMarker = () => {
      markerData.value = null;
      forecastVisible.value = false;
    };

    const onForecastToggle = (isVisible) => {
      forecastVisible.value = isVisible;
    };

    const changeDimension = (dimension) => {
      const newStyle = dimension === '3D' 
        ? 'mapbox://styles/mapbox/light-v11' 
        : 'mapbox://styles/mapbox/light-v10';
      
      if (mapStyle.value !== newStyle) {
        mapStyle.value = newStyle;
        
        // Store current camera position
        const center = map.getCenter();
        const zoom = map.getZoom();
        const bearing = map.getBearing();
        const pitch = map.getPitch();
        
        // Apply new style
        map.setStyle(newStyle);
        
        // After style is loaded, restore layers
        map.once('style.load', async () => {
          // Hide country labels for low zoom levels in 3D mode
          if (dimension === '3D') {
            hideCountryLabelsForLowZoom();
          }
          
          // Reload current layer
          await selectLayer();
          
          // Restore camera position
          map.jumpTo({
            center,
            zoom,
            bearing,
            pitch
          });
        });
      }
    };

    // Function to hide country labels for low zoom levels
    const hideCountryLabelsForLowZoom = () => {
      // Find all layers in the style
      const layers = map.getStyle().layers;
      
      // Approach 1: Hide all text labels at low zoom levels
      for (const layer of layers) {
        // Check if it's a symbol layer (which includes all text labels)
        if (layer.type === 'symbol') {
          // Set minzoom to at least 3 to hide at zoom levels 0-2
          map.setLayerZoomRange(layer.id, 3, 24);
        }
      }
      
      // Approach 2: Target specific layers by partial ID match
      const labelKeywords = [
        'label', 'text', 'name', 'place', 'city', 'town', 
        'village', 'settlement', 'state', 'country', 'poi'
      ];
      
      for (const layer of layers) {
        const layerId = layer.id.toLowerCase();
        
        // Check if layer ID contains any of our keywords
        if (labelKeywords.some(keyword => layerId.includes(keyword))) {
          map.setLayerZoomRange(layer.id, 3, 24);
        }
      }
    };

    const updateForecastVisibility = (isVisible) => {
      if (markerData.value) {
        markerData.value = {
          ...markerData.value,
          showForecast: isVisible
        };
      }
    };

    onMounted(async () => {
      map = new mapboxgl.Map({
        container: mapContainer.value,
        style: mapStyle.value,
        center: [0, 25],
        zoom: 2.5,
        attributionControl: false,
        maxZoom: 30,
        minZoom: 0,
        fadeDuration: 100,
      });      

      // Move both attribution and logo controls to bottom right
      map.addControl(new mapboxgl.AttributionControl({ 
        compact: true,
        customAttribution: null
      }), 'bottom-right');

      // Move the Mapbox logo to bottom right
      const logoControl = document.querySelector('.mapboxgl-ctrl-logo');
      if (logoControl) {
        const container = document.createElement('div');
        container.className = 'mapboxgl-ctrl-bottom-right';
        container.appendChild(logoControl);
        map.getContainer().appendChild(container);
      }

      // Wait for both style and map to be fully loaded
      await new Promise((resolve) => {
        if (map.loaded()) {
          resolve();
        } else {
          map.once('load', resolve);
        }
      });
      
      // Hide country labels for low zoom levels if in 3D mode
      if (savedDimension === '3D') {
        hideCountryLabelsForLowZoom();
      }

      // Add event listeners only after map and initial layer are loaded
      map.on('click', handleMapClick);
      map.on('move', updateMarkerScreenPosition);
      map.on('zoom', updateMarkerScreenPosition);

      await nextTick();
      mapHeight.value = mapContainer.value.clientHeight;
    });

    onUnmounted(() => {
      if (map) {
        map.off('click', handleMapClick);
        map.off('move', updateMarkerScreenPosition);
        map.off('zoom', updateMarkerScreenPosition);
        map.remove();
      }
    });

    return {
      mapContainer,
      currentLegendData,
      timestamps,
      currentTimestamp,
      layerUrl,
      mapHeight,
      markerData,
      forecastVisible,
      closeMarker,
      onForecastToggle,
      handleMapClick,
      updateMarkerScreenPosition,
      updateMarkerValue,
      changeLayer,
      changeTimestamp,
      changeDimension,
      handlePlayPauseChange,
      handlePlaceSelection,
      updateForecastVisibility
    };
  }
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.bottom-controls {
  position: absolute;
  bottom: 25px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10; /* Ensure controls are above the map */
  gap: 15px;
}

.time-slider {
  width: 83%;
  margin-bottom: 0;
  position: relative;
  bottom: 10px;
}

.legend {
  width: 15%;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

/* Adjust Mapbox attribution style for bottom-right position */
:deep(.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib) {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0 5px;
  margin: 0 10px 10px 0;
  border-radius: 3px;
  font-size: 10px;
}
:deep(.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib-button) {
  display: none;
}

/* Ensure Mapbox logo is positioned correctly */
:deep(.mapboxgl-ctrl-logo) {
  margin: 0 10px 10px 0;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .map-container {
    position: absolute;
    height: 100%;
    min-height: 100vh;
  }
  
  .bottom-controls {
    position: fixed;
    flex-direction: column;
    align-items: stretch;
    bottom: 10px;
    left: 10px;
    right: 10px;
    padding-bottom: 10px;
    gap: 10px;
  }
  
  .time-slider {
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    bottom: 0;
  }
  
  .legend {
    width: 100%;
    margin-bottom: 0;
    margin-top: 5px;
  }
}
</style>