<template>
  <div class="forecast">
    <div class="forecast-cell">{{ time }}</div>
    <div class="forecast-cell">
      <img :src="getWeatherIcon()" alt="Weather Icon" class="weather-icon" />
    </div>
    <div class="forecast-cell">{{ Math.round(temperature) }}°</div>
    <div class="forecast-cell">{{ precipitation > 0 ? precipitation.toFixed(1) : '' }}</div>
    <div class="forecast-cell wind-speed" :style="windSpeedGradient">
      {{ Math.round(windSpeed) }}
    </div>
    <div class="forecast-cell wind-gust" :style="windGustGradient">
      {{ Math.round(windGust) }}
    </div>
    <div class="forecast-cell wind-direction">
      <span class="arrow" :style="{ transform: `rotate(${windDirection + 180}deg)` }">↑</span>
    </div>
  </div>
</template>

<script>
import SunCalc from 'suncalc';

export default {
  name: 'SingleHourForecast',
  props: {
    time: {
      type: String,
      required: true
    },
    timestamp: {
      type: Date,
      required: true
    },
    coordinates: {
      type: Object,
      required: true,
      validator: (value) => {
        return value.lat !== undefined && value.lng !== undefined;
      }
    },
    clouds: {
      type: Number,
      required: true
    },
    temperature: {
      type: Number,
      required: true
    },
    precipitation: {
      type: Number,
      required: true
    },
    precipitationCategory: {
      type: Number,
      required: true
    },
    windSpeed: {
      type: Number,
      required: true
    },
    windGust: {
      type: Number,
      required: true
    },
    windDirection: {
      type: Number,
      required: true
    },
    fog: {
      type: Number,
      required: true
    },
    stormCategory: {
      type: Number,
      required: true
    },
    prevWindSpeed: {
      type: Number,
      default: null
    },
    nextWindSpeed: {
      type: Number,
      default: null
    },
    prevWindGust: {
      type: Number,
      default: null
    },
    nextWindGust: {
      type: Number,
      default: null
    },
    is3hMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    windSpeedGradient() {
      const centerColor = this.getWindColor(this.windSpeed);
      const leftColor = this.prevWindSpeed !== null ? this.getWindColor(this.prevWindSpeed) : centerColor;
      const rightColor = this.nextWindSpeed !== null ? this.getWindColor(this.nextWindSpeed) : centerColor;

      // Create a gradient that transitions smoothly between colors
      return {
        background: `linear-gradient(to right, 
          ${this.interpolateColor(leftColor, centerColor, 0.5)} 0%,
          ${centerColor} 50%,
          ${this.interpolateColor(centerColor, rightColor, 0.5)} 100%
        )`,
        margin: 0,
        padding: 0
      };
    },
    windGustGradient() {
      const centerColor = this.getWindColor(this.windGust);
      const leftColor = this.prevWindGust !== null ? this.getWindColor(this.prevWindGust) : centerColor;
      const rightColor = this.nextWindGust !== null ? this.getWindColor(this.nextWindGust) : centerColor;

      return {
        background: `linear-gradient(to right, 
          ${this.interpolateColor(leftColor, centerColor, 0.5)} 0%,
          ${centerColor} 50%,
          ${this.interpolateColor(centerColor, rightColor, 0.5)} 100%
        )`,
        margin: 0,
        padding: 0
      };
    }
  },
  methods: {
    getWeatherIcon() {
      let iconNumber;

      // Calculate each digit according to the schema
      const cloudsDigit = Math.round(this.clouds);
      const precipitationDigit = this.precipitation >= 2.0 ? 2 : (this.precipitation > 0 ? 1 : 0);
      const precipitationCategoryDigit = precipitationDigit === 0 ? 0 : Math.round(this.precipitationCategory);
      const stormCategoryDigit = Math.round(this.stormCategory);
      
      // Combine first 5 digits
      iconNumber = `${cloudsDigit}${precipitationDigit}${precipitationCategoryDigit}${stormCategoryDigit}0`;
      
      // Calculate if it's day or night using suncalc
      const sunTimes = SunCalc.getTimes(this.timestamp, this.coordinates.lat, this.coordinates.lng);
      const isNight = this.timestamp < sunTimes.sunrise || this.timestamp > sunTimes.sunset ? 1 : 0;
      
      // Append day/night digit
      iconNumber = `${iconNumber}${isNight}`;
      
      return `/icons/weather/${iconNumber}.png`;
    },
    getWindColor(value) {
      const ranges = [
        { from: 48, color: '#FA029C' },
        { from: 47, color: '#FB029C' },
        { from: 46, color: '#FB029B' },
        { from: 45, color: '#FB029B' },
        { from: 44, color: '#FC019A' },
        { from: 43, color: '#FC019A' },
        { from: 42, color: '#FD0199' },
        { from: 41, color: '#FD0199' },
        { from: 40, color: '#FE0199' },
        { from: 39, color: '#FE0199' },
        { from: 38, color: '#FF0096' },
        { from: 37, color: '#FF0393' },
        { from: 36, color: '#FF088D' },
        { from: 35, color: '#FF1380' },
        { from: 34, color: '#FF167D' },
        { from: 33, color: '#FF1E72' },
        { from: 32, color: '#FF2468' },
        { from: 31, color: '#FF3256' },
        { from: 30, color: '#FF3D49' },
        { from: 29, color: '#FF4243' },
        { from: 28, color: '#FF5330' },
        { from: 27, color: '#FF5E26' },
        { from: 26, color: '#FF6520' },
        { from: 25, color: '#FF7713' },
        { from: 24, color: '#FF810D' },
        { from: 23, color: '#FF9305' },
        { from: 22, color: '#FF8F07' },
        { from: 21, color: '#FFA301' },
        { from: 20, color: '#FDAC00' },
        { from: 19, color: '#F1B300' },
        { from: 18, color: '#C3C200' },
        { from: 17, color: '#A7CA00' },
        { from: 16, color: '#67DC00' },
        { from: 15, color: '#48E200' },
        { from: 14, color: '#31E600' },
        { from: 13, color: '#1EE800' },
        { from: 12, color: '#0AE802' },
        { from: 11, color: '#00EC39' },
        { from: 10, color: '#00E96F' },
        { from: 9, color: '#00D3E0' },
        { from: 8, color: '#1BCDFD' },
        { from: 7, color: '#76DEFA' },
        { from: 6, color: '#D2EEF5' },
        { from: 0, color: '#F4F4F4' }
      ];

      for (const range of ranges) {
        if (value >= range.from) {
          return range.color;
        }
      }
      return '#F4F4F4'; // Default color for any value below minimum
    },
    interpolateColor(color1, color2, factor) {
      // Convert hex to RGB
      const hex2rgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      };

      // Convert RGB to hex
      const rgb2hex = (r, g, b) => {
        return '#' + [r, g, b].map(x => {
          const hex = Math.round(x).toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        }).join('');
      };

      const c1 = hex2rgb(color1);
      const c2 = hex2rgb(color2);

      if (!c1 || !c2) return color1;

      return rgb2hex(
        c1.r + (c2.r - c1.r) * factor,
        c1.g + (c2.g - c1.g) * factor,
        c1.b + (c2.b - c1.b) * factor
      );
    }
  }
};
</script>

<style scoped>
.forecast {
  display: flex;
  flex-direction: column;
  width: 40px;
  margin: 0;
  padding: 0;
}

.forecast-cell {
  height: 30px;
  font-size: 15px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  margin: 0;
}

.forecast-cell.wind-gust,
.forecast-cell:nth-child(4) {  /* Precipitation cell */
  font-size: 10px;
}

.weather-icon {
  width: 40px;
  height: 40px;
}

.wind-direction {
  position: relative;
}

.arrow {
  font-size: 20px;
  display: inline-block;
  transform-origin: center;
  content: "↑";
  line-height: 1;
  height: 20px;
}

.wind-speed, .wind-gust {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
}
</style>
