<template>
  <div class="legend">
    <div class="legend-content">
      <div class="legend-unit">{{ legendData.unit }}</div>
      <div class="legend-items">
        <div v-for="(item, index) in selectedValues" :key="index" class="legend-item">
          <div class="color-box" :style="{ backgroundColor: item.color }">
            <span class="value">{{ item.from }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'LegendComponent',
  props: {
    legendData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const selectedValues = computed(() => {
      if (!props.legendData?.values) return [];

      const values = props.legendData.values;
      const totalItems = values.length;
      const maxItems = 8;
      
      if (totalItems <= maxItems) return values;
      
      const step = Math.floor(totalItems / (maxItems - 1));
      const result = [values[0]];
      for (let i = 1; i < maxItems - 1; i++) {
        result.push(values[i * step]);
      }
      result.push(values[totalItems - 1]);
      return result;
    });

    return {
      selectedValues
    };
  }
}
</script>

<style scoped>
.legend {
  padding: 0 0px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  font-size: 14px;
}

.legend-content {
  display: flex;
  align-items: center;
  padding: 1px 0;
}

.legend-unit {
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
  white-space: nowrap;
  flex-shrink: 0;
}

.legend-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.legend-item {
  flex: 1;
  margin: 0 0px;
}

.color-box {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.value {
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .legend-content {
    flex-direction: row;
    align-items: center;
  }
  
  .legend-unit {
    margin-right: 8px;
    margin-left: 5px;
    font-size: 10px;
  }
  
  .legend-items {
    width: auto;
    flex-grow: 1;
  }
  
  .color-box {
    height: 16px;
  }
  
  .value {
    font-size: 8px;
  }
}
</style>