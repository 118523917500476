<template>
  <div class="precipitation-chart">
    <svg :width="totalWidth" :height="height" class="chart">
      <!-- Path for the filled area -->
      <path
        v-for="(segment, index) in segments"
        :key="index"
        :d="segment.areaPath"
        class="area"
        fill="rgba(135, 206, 235, 0.5)"
      />
      
      <!-- Path for the line -->
      <path
        v-for="(segment, index) in segments"
        :key="'line-'+index"
        :d="segment.linePath"
        class="line"
        fill="none"
        stroke="rgb(135, 206, 235, 0.5)"
        stroke-width="0.5"
      />
    </svg>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'PrecipitationChart',
  props: {
    precipitation: {
      type: Array,
      required: true
    },
    hourWidth: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 30
    },
    maxPrecipitation: {
      type: Number,
      default: 5
    }
  },
  setup(props) {
    const totalWidth = computed(() => {
      return props.precipitation.length * (props.hourWidth / 2.5);
    });

    // Helper function to get point coordinates
    const getPointCoordinates = (value, index, cellWidth) => {
      const x = (index * cellWidth) + (cellWidth / 2);
      const normalizedValue = Math.min(value, props.maxPrecipitation);
      const y = props.height - (normalizedValue / props.maxPrecipitation) * props.height;
      return [x, y];
    };

    // Helper function to calculate control points for a smooth curve
    const getCurveControlPoints = (points) => {
      const controlPoints = [];
      
      for (let i = 0; i < points.length - 1; i++) {
        const current = points[i];
        const next = points[i + 1];
        const prev = points[i - 1] || current;
        const next2 = points[i + 2] || next;

        // Calculate control points based on surrounding points
        const tensionFactor = 0.25; // Adjust this value to control curve smoothness

        // First control point
        const cp1x = current[0] + (next[0] - prev[0]) * tensionFactor;
        const cp1y = current[1] + (next[1] - prev[1]) * tensionFactor;

        // Second control point
        const cp2x = next[0] - (next2[0] - current[0]) * tensionFactor;
        const cp2y = next[1] - (next2[1] - current[1]) * tensionFactor;

        controlPoints.push({
          cp1: [cp1x, cp1y],
          cp2: [cp2x, cp2y]
        });
      }

      return controlPoints;
    };

    // Create smooth curve path
    const createSmoothCurvePath = (points) => {
      if (points.length < 2) return '';

      const controlPoints = getCurveControlPoints(points);
      let path = `M ${points[0][0]} ${points[0][1]}`;

      for (let i = 0; i < points.length - 1; i++) {
        const { cp1, cp2 } = controlPoints[i];
        const nextPoint = points[i + 1];
        path += ` C ${cp1[0]} ${cp1[1]}, ${cp2[0]} ${cp2[1]}, ${nextPoint[0]} ${nextPoint[1]}`;
      }

      return path;
    };

    const segments = computed(() => {
      const result = [];
      const cellWidth = props.hourWidth / 2.5;

      // Find continuous segments of non-zero precipitation
      let segmentStart = -1;
      
      for (let i = 0; i < props.precipitation.length; i++) {
        if (props.precipitation[i] > 0 && segmentStart === -1) {
          // Start new segment, including one zero point before if available
          segmentStart = Math.max(0, i - 1);
        } else if (props.precipitation[i] === 0 && segmentStart !== -1) {
          // End segment, including this zero point
          const segmentPoints = [];
          
          // Add points for the segment
          for (let j = segmentStart; j <= i; j++) {
            const point = getPointCoordinates(props.precipitation[j], j, cellWidth);
            segmentPoints.push(point);
          }

          if (segmentPoints.length >= 2) {
            const linePath = createSmoothCurvePath(segmentPoints);
            const areaPath = `${linePath} L ${segmentPoints[segmentPoints.length-1][0]} ${props.height} L ${segmentPoints[0][0]} ${props.height} Z`;
            result.push({ linePath, areaPath });
          }

          segmentStart = -1;
        }
      }

      // Handle last segment if it ends with precipitation
      if (segmentStart !== -1) {
        const segmentPoints = [];
        
        // Add points for the segment
        for (let j = segmentStart; j < props.precipitation.length; j++) {
          const point = getPointCoordinates(props.precipitation[j], j, cellWidth);
          segmentPoints.push(point);
        }

        // Add final zero point if we end with precipitation
        if (props.precipitation[props.precipitation.length - 1] > 0) {
          const lastPoint = getPointCoordinates(0, props.precipitation.length, cellWidth);
          segmentPoints.push(lastPoint);
        }

        if (segmentPoints.length >= 2) {
          const linePath = createSmoothCurvePath(segmentPoints);
          const areaPath = `${linePath} L ${segmentPoints[segmentPoints.length-1][0]} ${props.height} L ${segmentPoints[0][0]} ${props.height} Z`;
          result.push({ linePath, areaPath });
        }
      }

      return result;
    });

    return {
      totalWidth,
      segments
    };
  }
};
</script>

<style scoped>
.precipitation-chart {
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.chart {
  overflow: visible;
}

.line {
  transition: d 0.5s ease-out;
}

.area {
  transition: d 0.5s ease-out;
}
</style>