<template>
  <div class="time-series-forecast" v-if="isVisible" @click.stop @mousedown.stop @touchstart.stop>
    <div class="content">
      <div class="forecast-container" v-if="forecastData">
        <div class="labels">
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Time</span>
              <span class="mobile-label">Time</span>
            </span>
          </div>
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Weather</span>
              <span class="mobile-label"></span>
            </span>
          </div>
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Temperature</span>
              <span class="mobile-label">Temp</span>
            </span>
            <span class="unit">℃</span>
          </div>
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Precipitation</span>
              <span class="mobile-label">Precip</span>
            </span>
            <span class="unit">mm</span>
          </div>
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Wind Speed</span>
              <span class="mobile-label">Wind</span>
            </span>
            <span class="unit">kt</span>
          </div>
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Wind Gust</span>
              <span class="mobile-label">Gust</span>
            </span>
            <span class="unit">kt</span>
          </div>
          <div class="forecast-cell">
            <span class="attribute-name">
              <span class="desktop-label">Wind Direction</span>
              <span class="mobile-label">Dir</span>
            </span>
          </div>
        </div>
        <div class="forecast-section">
          <div class="scrollable-content">
            <div class="scrollable-inner">
              <DayHeader 
                :issue-time="forecastData.issueTime"
                :hours-from-issue-time="filteredHoursFromIssueTime"
                :hour-width="100"
              />
              <CurrentTimeLine
                :issue-time="forecastData.issueTime"
                :hours-from-issue-time="filteredHoursFromIssueTime"
                :hour-width="100"
              />
              <div class="precipitation-chart-container" v-if="forecastData">
                <PrecipitationChart
                  :precipitation="filteredPrecipitation"
                  :hour-width="100"
                  :height="30"
                  :max-precipitation="5"
                />
              </div>
              <div class="forecasts">
                <SingleHourForecast
                  v-for="(hour, index) in filteredHoursFromIssueTime"
                  :key="index"
                  :time="formatTime(forecastData.issueTime, hour)"
                  :timestamp="getTimestamp(forecastData.issueTime, hour)"
                  :coordinates="coordinates"
                  :clouds="getFilteredValue(forecastData.Clouds, hour)"
                  :temperature="getFilteredValue(forecastData.Temperature, hour)"
                  :precipitation="getFilteredValue(forecastData.Precipitation, hour)"
                  :precipitation-category="getFilteredValue(forecastData.PrecipitationCategory, hour)"
                  :wind-speed="getFilteredValue(forecastData.WindSpeed, hour)"
                  :wind-gust="getFilteredValue(forecastData.WindGust, hour)"
                  :wind-direction="getFilteredValue(forecastData.WindDirection, hour)"
                  :fog="getFilteredValue(forecastData.Fog, hour)"
                  :storm-category="getFilteredValue(forecastData.StormCategory, hour)"
                  :prev-wind-speed="getPrevValue(forecastData.WindSpeed, filteredHoursFromIssueTime, index)"
                  :next-wind-speed="getNextValue(forecastData.WindSpeed, filteredHoursFromIssueTime, index)"
                  :prev-wind-gust="getPrevValue(forecastData.WindGust, filteredHoursFromIssueTime, index)"
                  :next-wind-gust="getNextValue(forecastData.WindGust, filteredHoursFromIssueTime, index)"
                  :is3hMode="forecastInterval === '3h'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="interval-toggle">
        <button 
          :class="{ active: forecastInterval === '1h' }" 
          @click="setForecastInterval('1h')"
        >
          1h
        </button>
        <button 
          :class="{ active: forecastInterval === '3h' }" 
          @click="setForecastInterval('3h')"
        >
          3h
        </button>
      </div>
      <button class="close-button" @click="handleClose">×</button>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import SingleHourForecast from './SingleHourForecast.vue';
import DayHeader from './DayHeader.vue';
import CurrentTimeLine from './CurrentTimeLine.vue';
import PrecipitationChart from './PrecipitationChart.vue';

export default {
  name: 'TimeSeriesForecast',
  components: {
    SingleHourForecast,
    DayHeader,
    CurrentTimeLine,
    PrecipitationChart
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    coordinates: {
      type: Object,
      required: true,
      validator: (value) => {
        return Object.prototype.hasOwnProperty.call(value, 'lat') && 
               Object.prototype.hasOwnProperty.call(value, 'lng');
      }
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const forecastData = ref(null);
    const isVisible = ref(props.show);
    const forecastInterval = ref(localStorage.getItem('forecastInterval') || '3h');

    const filteredHoursFromIssueTime = computed(() => {
      if (!forecastData.value) return [];
      
      if (forecastInterval.value === '1h') {
        return forecastData.value.hoursFromIssueTime;
      }
      
      // For 3h interval, only show hours that are divisible by 3 in UTC
      return forecastData.value.hoursFromIssueTime.filter(hour => {
        const date = getTimestamp(forecastData.value.issueTime, hour);
        const utcHour = date.getUTCHours();
        return [0, 3, 6, 9, 12, 15, 18, 21].includes(utcHour);
      });
    });

    const filteredPrecipitation = computed(() => {
      if (!forecastData.value) return [];
      
      if (forecastInterval.value === '1h') {
        return forecastData.value.Precipitation;
      }
      
      // Filter precipitation data to match filtered hours
      const result = [];
      const filteredHours = filteredHoursFromIssueTime.value;
      const allHours = forecastData.value.hoursFromIssueTime;
      
      filteredHours.forEach(hour => {
        const index = allHours.indexOf(hour);
        if (index !== -1) {
          result.push(forecastData.value.Precipitation[index]);
        }
      });
      
      return result;
    });

    const getFilteredValue = (array, hour) => {
      if (!forecastData.value) return 0;
      
      const allHours = forecastData.value.hoursFromIssueTime;
      const hourIndex = allHours.indexOf(hour);
      
      if (hourIndex === -1) return 0;
      
      // For 1h interval, just return the value
      if (forecastInterval.value === '1h') {
        return array[hourIndex];
      }
      
      // For 3h interval, calculate average from neighboring cells
      // Check if we're in the future forecast where only 3h intervals exist
      const date = getTimestamp(forecastData.value.issueTime, hour);
      const utcHour = date.getUTCHours();
      const isExactThreeHourInterval = [0, 3, 6, 9, 12, 15, 18, 21].includes(utcHour);
      
      // If we're at an exact 3-hour interval in the future part of the forecast
      // where only 3h data exists, just return the value without averaging
      if (isExactThreeHourInterval && hourIndex >= allHours.length - 24) {
        return array[hourIndex];
      }
      
      // Calculate average from current hour and neighboring hours
      let sum = array[hourIndex];
      let count = 1;
      
      // Try to include previous hour
      if (hourIndex > 0) {
        sum += array[hourIndex - 1];
        count++;
      }
      
      // Try to include next hour
      if (hourIndex < array.length - 1) {
        sum += array[hourIndex + 1];
        count++;
      }
      
      return Math.round((sum / count) * 10) / 10; // Round to 1 decimal place
    };

    const getPrevValue = (array, hours, index) => {
      if (!forecastData.value || index <= 0) return null;
      
      // Get the previous visible hour
      const prevHour = hours[index - 1];
      const allHours = forecastData.value.hoursFromIssueTime;
      const prevIndex = allHours.indexOf(prevHour);
      
      if (prevIndex >= 0) {
        // For 3h mode, we need to return the averaged value
        if (forecastInterval.value === '3h') {
          return getFilteredValue(array, prevHour);
        }
        // For 1h mode, return the original value
        return array[prevIndex];
      }
      
      return null;
    };

    const getNextValue = (array, hours, index) => {
      if (!forecastData.value || index >= hours.length - 1) return null;
      
      // Get the next visible hour
      const nextHour = hours[index + 1];
      const allHours = forecastData.value.hoursFromIssueTime;
      const nextIndex = allHours.indexOf(nextHour);
      
      if (nextIndex < array.length) {
        // For 3h mode, we need to return the averaged value
        if (forecastInterval.value === '3h') {
          return getFilteredValue(array, nextHour);
        }
        // For 1h mode, return the original value
        return array[nextIndex];
      }
      
      return null;
    };

    const fetchForecast = async () => {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_TIMESERIES_API_URL}/timeseries?lat=${props.coordinates.lat}&lon=${props.coordinates.lng}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        forecastData.value = data;
      } catch (error) {
        console.error('Error fetching forecast:', error);
      }
    };

    const formatTime = (issueTime, hoursFromIssue) => {
      // Create a date object in UTC
      const year = parseInt(issueTime.substring(0, 4));
      const month = parseInt(issueTime.substring(4, 6)) - 1;
      const day = parseInt(issueTime.substring(6, 8));
      const hour = parseInt(issueTime.substring(9, 11));
      
      // Create date in local time zone
      const date = new Date(Date.UTC(year, month, day, hour));
      
      // Add the hours from issue time
      date.setUTCHours(date.getUTCHours() + hoursFromIssue);
      
      // Get local hour
      const localHour = date.getHours();
      
      // Format with leading zero but no minutes
      return localHour < 10 ? `0${localHour}` : `${localHour}`;
    };

    const getTimestamp = (issueTime, hoursFromIssue) => {
      // Create a date object in UTC
      const year = parseInt(issueTime.substring(0, 4));
      const month = parseInt(issueTime.substring(4, 6)) - 1;
      const day = parseInt(issueTime.substring(6, 8));
      const hour = parseInt(issueTime.substring(9, 11));
      
      // Create date in local time zone
      const date = new Date(Date.UTC(year, month, day, hour));
      
      // Add the hours from issue time
      date.setUTCHours(date.getUTCHours() + hoursFromIssue);
      
      return date;
    };

    const calculateIcon = (index) => {
      if (!forecastData.value) return 0;
      return Math.round(
        forecastData.value.Clouds[index] * 
        forecastData.value.Fog[index] * 
        forecastData.value.PrecipitationCategory[index] * 
        forecastData.value.StormCategory[index]
      );
    };

    const handleClose = () => {
      isVisible.value = false;
      emit('close');
    };

    const setForecastInterval = (interval) => {
      forecastInterval.value = interval;
      localStorage.setItem('forecastInterval', interval);
    };

    watch(() => props.show, (newValue) => {
      isVisible.value = newValue;
      if (newValue && props.coordinates) {
        fetchForecast();
      }
    });

    watch(() => props.coordinates, (newValue) => {
      if (isVisible.value && newValue) {
        fetchForecast();
      }
    }, { deep: true });

    watch(
      () => ({ show: props.show, coordinates: props.coordinates }),
      (newValue) => {
        if (newValue.show && newValue.coordinates) {
          fetchForecast();
        }
      },
      { immediate: true, deep: true }
    );

    return {
      forecastData,
      formatTime,
      getTimestamp,
      calculateIcon,
      isVisible,
      handleClose,
      forecastInterval,
      filteredHoursFromIssueTime,
      filteredPrecipitation,
      getFilteredValue,
      getPrevValue,
      getNextValue,
      setForecastInterval
    };
  }
}
</script>

<style scoped>
.time-series-forecast {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 310px;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  pointer-events: auto;
}

.content {
  position: relative;
  height: 100%;
}

.forecast-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: calc(100% - 40px);
}

.labels {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  min-width: 150px;
  flex-shrink: 0;
  padding-top: 27px;
}

.labels .forecast-cell {
  width: auto;
  height: 30px;
  font-size: 18px;
  color: #666;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;
}

.attribute-name {
  flex: 1;
  white-space: nowrap;
}

.unit {
  color: #888;
  font-size: 18px;
  white-space: nowrap;
}

.unit:hover {
  color: #666;
}

.forecast-section {
  flex: 1;
  min-width: 0;
  position: relative;
}

.scrollable-content {
  overflow-x: auto;
  width: 100%;
  height: 100%;
}

.scrollable-inner {
  display: inline-block;
  min-width: 100%;
  position: relative;
  padding-top: 20px;
  white-space: nowrap;
}

.forecasts {
  display: flex;
  padding-bottom: 10px;
  gap: 0;
}

.precipitation-chart-container {
  position: absolute;
  top: 115px; /* Position at precipitation row level */
  left: 0;
  right: 0;
  height: 30px;
  z-index: 1;
}

.interval-toggle {
  position: absolute;
  bottom: 30px;
  left: 10px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.interval-toggle button {
  padding: 6px 12px;
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  transition: background-color 0.2s, color 0.2s;
}

.interval-toggle button:hover {
  background-color: #f0f0f0;
}

.interval-toggle button.active {
  background-color: #ff8c00; /* Orange color to match the map controls */
  color: white;
}

.close-button {
  position: absolute;
  top: -5px;
  right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgb(157, 3, 0);
  border: none;
  color: white;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 1001;
  line-height: 0.8;
  padding-bottom: 5px;
}

.close-button:hover {
  background-color: rgb(127, 2, 0);
}

.close-button span {
  line-height: 1;
  margin-top: -5px;
}

.desktop-label {
  display: block;
}

.mobile-label {
  display: none;
  font-size: 16px;
}

@media (max-width: 768px) {
  .labels {
    min-width: 50px;
  }

  .unit {
    font-size: 12px;
  }

  .desktop-label {
    display: none;
  }

  .mobile-label {
    display: block;
  }

  .interval-toggle {
    bottom: 35px;
    left: 15px;
  }
  
  .interval-toggle button {
    padding: 5px 10px;
    font-size: 10px;
  }
  
  .close-button {
    font-size: 35px;
    line-height: 0.8;
    padding-bottom: 5px;
  }
}
</style>
