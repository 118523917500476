<template>
  <div class="day-markers">
    <div v-for="(day, index) in dayMarkers" :key="index" class="day-marker" :style="{ left: day.position + '%' }">
      <div class="day-marker-line"></div>
    </div>
    <div v-for="(label, index) in dayLabels" :key="index" class="day-label" :style="{ left: label.position + '%' }">
      {{ label.text }}
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';

export default {
  name: 'DayMarkers',
  props: {
    timestamps: {
      type: Array,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const isMobile = ref(false);

    onMounted(() => {
      // Check if mobile on mount
      checkIfMobile();
      // Add resize listener
      window.addEventListener('resize', checkIfMobile);
    });

    const checkIfMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    const parseTimestamp = (timestamp) => {
      // Remove the "/Z3Z" suffix if present
      const cleanTimestamp = timestamp.replace('/Z3Z', '');
      const matches = cleanTimestamp.match(/^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/);
      if (matches) {
        return new Date(Date.UTC(
          parseInt(matches[1]),
          parseInt(matches[2]) - 1,
          parseInt(matches[3]),
          parseInt(matches[4]),
          parseInt(matches[5]),
          parseInt(matches[6])
        ));
      }
      return new Date(NaN);
    };

    const dayMarkers = computed(() => {
      const markers = [];
      let currentDay = null;
      props.timestamps.forEach((timestamp, index) => {
        const date = parseTimestamp(timestamp);
        if (date.getDate() !== currentDay) {
          currentDay = date.getDate();
          const position = (index / (props.timestamps.length - 1)) * 100;
          markers.push({ position, date });
        }
      });
      return markers;
    });

    const dayLabels = computed(() => {
      const labels = [];
      
      for (let i = 0; i < dayMarkers.value.length - 1; i++) {
        const start = dayMarkers.value[i].position;
        const end = dayMarkers.value[i + 1].position;
        const position = (start + end) / 2;
        const date = parseTimestamp(props.timestamps[Math.floor(position / 100 * props.timestamps.length)]);
        
        let text;
        if (isMobile.value) {
          // For mobile: first 5 days show weekday and day, rest show only day
          const dayIndex = i;
          if (dayIndex < 5 && dayIndex >= 1) {
            text = date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
            text = text.split(' ').reverse().join(' ');
          } else {
            text = date.toLocaleDateString('en-US', { day: 'numeric' });
          }
        } else {
          // For desktop: show weekday and day for all
          text = date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
          text = text.split(' ').reverse().join(' ');
        }
        
        labels.push({ position, text });
      }
      return labels;
    });

    return {
      dayMarkers,
      dayLabels,
      isMobile
    };
  }
};
</script>

<style scoped>
.day-markers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15px;
}

.day-marker {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}

.day-marker-line {
  width: 1px;
  height: 15px;
  background-color: rgba(50, 50, 50, 0.8);
}

.day-label {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
  color: rgba(50, 50, 50, 0.9);
  font-weight: 600;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.8);
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .day-markers {
    bottom: -5px;
  }
  
  .day-marker-line {
    height: 12px;
  }
  
  .day-label {
    font-size: 9px;
    line-height: 12px;
  }
}
</style>