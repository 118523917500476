<template>
  <div class="map-controls">
    <div class="dimension-toggle">
      <button 
        :class="{ active: dimension === '2D' }" 
        @click="setDimension('2D')"
      >
        2D
      </button>
      <button 
        :class="{ active: dimension === '3D' }" 
        @click="setDimension('3D')"
      >
        3D
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'MapControls',
  emits: ['dimension-changed'],
  setup(props, { emit }) {
    // Initialize with default, but we'll load from localStorage if available
    const dimension = ref('2D');
    
    // Load saved preference on component mount
    onMounted(() => {
      const savedDimension = localStorage.getItem('mapDimension');
      if (savedDimension) {
        dimension.value = savedDimension;
        // Emit the saved dimension to update the map on initial load
        emit('dimension-changed', savedDimension);
      }
    });
    
    const setDimension = (newDimension) => {
      if (dimension.value !== newDimension) {
        dimension.value = newDimension;
        // Save to localStorage for persistence
        localStorage.setItem('mapDimension', newDimension);
        emit('dimension-changed', newDimension);
      }
    };

    return {
      dimension,
      setDimension
    };
  }
};
</script>

<style scoped>
.map-controls {
  position: absolute;
  right: 20px;
  bottom: 70px; /* Position above the legend */
  z-index: 1;
}

.dimension-toggle {
  display: flex;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.dimension-toggle button {
  padding: 6px 12px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  transition: background-color 0.2s, color 0.2s;
}

.dimension-toggle button:hover {
  background-color: #f0f0f0;
}

.dimension-toggle button.active {
  background-color: #ff8c00; /* Changed from #0078ff (blue) to #ff8c00 (orange) */
  color: white;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .map-controls {
    position: fixed; /* Change to fixed to ensure consistent positioning */
    right: 10px;
    bottom: 85px; /* Increase this value to position it higher above the time slider */
    z-index: 15; /* Increase z-index to ensure it's above other controls */
  }
  
  .dimension-toggle {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15); /* Stronger shadow for better visibility */
  }

  .dimension-toggle button {
    font-size: 10px;
  }

}
</style>
