<template>
  <div class="value-marker" :style="markerStyle">
    <div class="marker-line"></div>
    <div class="marker-circle"></div>
    <div class="marker-value">
      {{ value ? value + ' ' + unit : '0 ' + unit + '  '  }}
      <div class="close-button" @click.stop="$emit('close-marker')">x</div>
      <button class="forecast-button" @click.stop="toggleForecast">
        <span>&#x2304;</span>
      </button>
    </div>
    <TimeSeriesForecast
      :show="showForecast"
      :coordinates="coordinates"
      @close="closeForecast"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import TimeSeriesForecast from './TimeSeriesForecast.vue';

export default {
  name: 'ValueMarker',
  components: {
    TimeSeriesForecast
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    mapHeight: {
      type: Number,
      required: true
    },
    coordinates: {
      type: Object,
      required: true
    },
    initialShowForecast: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-marker', 'forecast-visibility-changed'],
  setup(props, { emit }) {
    const showForecast = ref(props.initialShowForecast);
    
    // Watch for changes to initialShowForecast prop
    watch(() => props.initialShowForecast, (newValue) => {
      showForecast.value = newValue;
    });
    
    const toggleForecast = () => {
      showForecast.value = !showForecast.value;
      emit('forecast-visibility-changed', showForecast.value);
    };
    
    const closeForecast = () => {
      showForecast.value = false;
      emit('forecast-visibility-changed', false);
    };
    
    return { 
      showForecast,
      toggleForecast,
      closeForecast
    };
  },
  computed: {
    markerStyle() {
      return {
        left: this.position.x + 'px',
        top: this.position.y + 'px'
      };
    }
  }
};
</script>

<style scoped>
.value-marker {
  position: absolute;
  pointer-events: none;
}

.marker-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: -3px;
  top: -3px;
  z-index: 2;
}

.marker-line {
  width: 1px;
  height: calc(7vh + 3px);
  background-color: #4a4a4a;
  position: absolute;
  left: 0;
  top: calc(-7vh - 3px);
  z-index: 1;
}

.marker-value {
  position: absolute;
  top: calc(-7vh - 18px);
  left: -1px;
  background-color: #4a4a4a;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  white-space: nowrap;
  position: relative;
  pointer-events: auto;
}

.close-button {
  position: absolute;
  top: -5px;
  right: -45px;
  width: 20px;
  height: 20px;
  background-color: #4a4a4a;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
}

.forecast-button {
  position: absolute;
  top: -1px;
  right: -20px;
  width: 25px;
  height: 25px;
  background-color: #ffa500;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 24px;
  line-height: 0;
  padding: 0;
}

.forecast-button:hover {
  background-color: #ff8c00;
}

.forecast-button span {
  display: block;
  transform: scaleX(1.5);
  margin-top: -16px;
}

@media screen and (max-width: 768px) {
  .close-button {
    top: -15px;
    width: 30px;
    height: 30px;
    font-size: 23px;
    line-height: 28px;
    right: -55px;
  }

  .forecast-button span {
    margin-top: -14px;
  }

}
</style>
