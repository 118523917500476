<template>
  <MapComponent />
</template>

<script>
import MapComponent from './components/MapComponent.vue'

export default {
  name: 'App',
  components: {
    MapComponent
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

html, body {
  height: 100%;
  width: 100%;
}

#app {
  height: 100vh;
  width: 100vw;
}

/* Global styles for better touch experience */
button, 
[role="button"],
a,
input,
select,
.clickable,
.time-slider,
.play-pause-button {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  html, body {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  #app {
    position: relative;
    height: auto;
    min-height: 100vh;
  }
}
</style>