<template>
  <div 
    class="current-time-line"
    :style="{ left: linePosition + 'px' }"
  ></div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CurrentTimeLine',
  props: {
    issueTime: {
      type: String,
      required: true
    },
    hoursFromIssueTime: {
      type: Array,
      required: true
    },
    hourWidth: {
      type: Number,
      default: 100
    }
  },
  setup(props) {
    const parseDate = (dateStr) => {
      try {
        // Format expected: YYYYMMDD_HH
        const year = parseInt(dateStr.substring(0, 4));
        const month = parseInt(dateStr.substring(4, 6)) - 1; // JS months are 0-indexed
        const day = parseInt(dateStr.substring(6, 8));
        const hour = parseInt(dateStr.substring(9, 11));

        // Create a Date object in UTC
        const date = new Date(Date.UTC(year, month, day, hour));

        return date;
      } catch (error) {
        console.error('Error parsing date:', error);
        return new Date();
      }
    };

    const getCurrentTimeInTimeZone = () => {
      try {
        const now = new Date();

        // Format the date to a string in the specified time zone
        const options = { timeZone: props.timeZone };
        const formattedDate = now.toLocaleString('en-US', options);

        // Parse the formatted date back to a Date object
        return new Date(formattedDate);
      } catch (error) {
        console.error('Error getting time in time zone:', error);
        return new Date(); // Fallback to local time
      }
    };

    const linePosition = computed(() => {
      if (!props.issueTime || !props.hoursFromIssueTime.length) {
        return 0;
      }

      try {
        const issueDate = parseDate(props.issueTime);
        const currentTime = getCurrentTimeInTimeZone();

        // Calculate hours from issue time to current time
        const hoursDiff = (currentTime - issueDate) / (1000 * 60 * 60);

        // Find the closest hour index in the forecast
        const closestHourIndex = props.hoursFromIssueTime.findIndex(hour => hour >= hoursDiff);

        if (closestHourIndex === -1) {
          // Current time is beyond forecast range
          // Return the position at the last hour in the forecast
          return props.hoursFromIssueTime.length * (props.hourWidth / 2.5);
        }

        // If we're at the first index but the current time is before it
        if (closestHourIndex === 0 && hoursDiff < props.hoursFromIssueTime[0]) {
          return 0;
        }

        // For intermediate positions, interpolate between the two nearest hours
        if (closestHourIndex > 0) {
          const prevHour = props.hoursFromIssueTime[closestHourIndex - 1];
          const nextHour = props.hoursFromIssueTime[closestHourIndex];
          const hourFraction = (hoursDiff - prevHour) / (nextHour - prevHour);

          const prevPosition = (closestHourIndex - 1) * (props.hourWidth / 2.5);
          const nextPosition = closestHourIndex * (props.hourWidth / 2.5);
          
          // Add 0.5 to have proper position - why - I don't know
          return prevPosition + (hourFraction + 0.5) * (nextPosition - prevPosition);
        }

        // Calculate position based on the same formula as day headers
        const position = closestHourIndex * (props.hourWidth / 2.5);
        return position;
      } catch (error) {
        console.error('Error calculating current time position:', error);
        return 0;
      }
    });

    return {
      linePosition
    };
  }
};
</script>

<style scoped>
.current-time-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  border-left: 2px dashed orange;
  pointer-events: none;
  z-index: 2;
}
</style>